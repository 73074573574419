export const MERGE_LINK_IFRAME_ID = "merge-link-iframe";

export const getIFrame = (): HTMLIFrameElement => {
  return document.getElementById(MERGE_LINK_IFRAME_ID) as HTMLIFrameElement;
};

export const getMergeLinkURL = () => {
  if (process.env.REACT_APP_MERGE_PROD_FROM_LOCAL) {
    return "http://localhost:3005/index.html";
  }
  switch (process.env.REACT_APP_MERGE_ENV) {
    case "PRODUCTION":
      return "https://cdn.merge.dev/index.html";
    case "DEVELOP":
      return "https://develop-cdn.merge.dev/index.html";
    case "LOCAL":
    default:
      return "http://localhost:3005/index.html";
  }
};
